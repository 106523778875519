<template>
  <div class="card">
    <!-- <p class="title">广告牌</p> -->
    <p class="title">{{ isEdit ? "新增" : "修改" }}广告牌</p>
    <van-form
      @submit="onSubmit"
      :submit-on-enter="false"
      validate-trigger="onSubmit"
      scroll-to-error
      error-message-align="right"
    >
      <van-field
        @keydown="handleInput"
        v-model.number="form.EastWestDistance"
        input-align="right"
        label="东西长度（m）"
        type="number"
        :rules="[{ required: true, message: '请填写东西长度' }]"
        @focus="$event.target.select()"
      />
      <van-field
        @keydown="handleInput"
        v-model.number="form.SourthNorthDistance"
        input-align="right"
        label="南北宽度（m）"
        type="number"
        :rules="[{ required: true, message: '请填写南北宽度' }]"
        @focus="$event.target.select()"
      />
      <van-field
        @keydown="handleInput"
        v-model.number="form.Height"
        input-align="right"
        label="突出高度（m）"
        type="number"
        :rules="[{ required: true, message: '请填写突出高度' }]"
        @focus="$event.target.select()"
      />
      <van-field
        @keydown="handleInput"
        v-model.number="form.XCoordinates"
        input-align="right"
        label="距西南角X坐标"
        type="number"
        :rules="[{ required: true, message: '请填写距西南角X坐标' }]"
        @focus="$event.target.select()"
      />
      <van-field
        @keydown="handleInput"
        v-model.number="form.YCoordinates"
        input-align="right"
        label="距西南角Y坐标"
        type="number"
        :rules="[{ required: true, message: '请填写距西南角Y坐标' }]"
        @focus="$event.target.select()"
      />
      <div class="item">
        <van-field label="是否可移除" input-align="right" readonly />
        <span class="span1" @click.stop="IsRemovableTypeShow = true"
        >{{ form.TrueOrFalseRemove }}
          <img src="@/assets/images/下拉1.png" alt="" />
        </span>
        <ul class="options widthSpan1 twoline" v-show="IsRemovableTypeShow">
          <li @click="chooseIsRemovable('是')">是</li>
          <li @click="chooseIsRemovable('否')">否</li>
        </ul>
      </div>
      <div class="btnbox">
        <div class="cancel" @click="cancelEvent">取消</div>
        <div class="cancel" v-if="!isEdit" @click="del">删除</div>
        <van-button v-if="isEdit" native-type="submit" class="determine"> 确定 </van-button>
        <span
          v-if="!isEdit"
          @click="editDetermine"
          class="determine"
        >确定</span
        >
      </div>
    </van-form>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { guid } from '@/plugins/guid'
import emitter from '../../plugins/eventBus'
export default {
  emits: ['popupClose'],
  setup(props, { emit }) {
    // const globleData = getCurrentInstance().appContext.app.config.globalProperties.$globleData

    const data = reactive({
      form: {
        id: guid(),
        roofID: guid(),
        EastWestDistance: '', // 东西长度（CM）
        SourthNorthDistance: '', // 南北宽度（CM）
        Height: '', // 突出高度
        XCoordinates: '', // 距西南角X坐标
        YCoordinates: '', // 距西南角Y坐标
        TrueOrFalseRemove: '否', // 是否可移除 1：可移除  2：不可移除
      },
      IsRemovableTypeShow: false,
      isEdit: true,
      hort: {},
    })

    emitter.on('editRoomOutObstacleAds', (value) => {
      data.hort = value
      data.isEdit = false

      data.form.EastWestDistance = data.hort.eastwestDistance / 100
      data.form.SourthNorthDistance = data.hort.sourthnorthDistance / 100
      data.form.Height = data.hort.height / 100
      data.form.XCoordinates = data.hort.X / 100
      data.form.YCoordinates = data.hort.Y / 100
      if (data.hort.isRemove === 1) {
        data.form.TrueOrFalseRemove = '是'
      } else {
        data.form.TrueOrFalseRemove = '否'
      }
    })

    emitter.on('editstateRoomOutObstacleAds', (val) => {
      if (val === 1) {
        data.isEdit = false
      } else if (val === 2) {
        data.isEdit = true
      }
    })

    const methodsMap = {
      handleInput(e) {
        // 通过正则过滤小数点后两位
        // e.target.value = (e.target.value.match(/^\d*(\.?\d{0,1})/g)[0]) || null
        e.target.value = (e.target.value.match(/^(\-)*\d*(\.?\d{0,1})/g)[0]) || null
      },
      editDetermine() {
        if (data.isEdit === false) {
          const dataTo2d = {}

          data.hort.eastwestDistance = data.form.EastWestDistance * 100
          data.hort.sourthnorthDistance = data.form.SourthNorthDistance * 100
          data.hort.height = data.form.Height * 100
          data.hort.X = data.form.XCoordinates * 100
          data.hort.Y = data.form.YCoordinates * 100
          if (data.form.TrueOrFalseRemove === '是') {
            data.hort.isRemove = 1
          } else {
            data.hort.isRemove = 0
          }
          if (sessionStorage.getItem('roofType') === '平屋顶') {
            Object.assign(dataTo2d, data.hort, { name: "编辑广告牌" })
          }
          if (sessionStorage.getItem('roofType') === '斜屋顶') {
            Object.assign(dataTo2d, data.hort, { name: "编辑斜屋顶广告牌" })
          }
          if (sessionStorage.getItem('roofType') === '内厦屋顶') {
            Object.assign(dataTo2d, data.hort, { name: "编辑内厦屋顶广告牌" })
          }
          if (sessionStorage.getItem('roofType') === '外厦带平台屋顶') {
            Object.assign(dataTo2d, data.hort, { name: "编辑外厦带平台屋顶广告牌" })
          }
          // Object.assign(dataTo2d, data.form, { name: "编辑广告牌" })
          emit('popupClose', dataTo2d)
          data.isEdit = true
          data.form = {
            EastWestDistance: '',
            SourthNorthDistance: '',
            Height: '',
            XCoordinates: '',
            YCoordinates: '',
            TrueOrFalseRemove: '否',
          }
        }
      },
      chooseIsRemovable(item) {
        data.form.TrueOrFalseRemove = item
        data.IsRemovableTypeShow = false
      },

      onSubmit() {
        if (data.isEdit === true) {
          const dataTo2d = {}
          for (const i in data.form) {
            if (i !== 'TrueOrFalseRemove' && i !== 'id' && i !== 'roofID') {
              data.form[i] = data.form[i] * 100
            }
          }
          if (sessionStorage.getItem('roofType') === '平屋顶') {
            Object.assign(dataTo2d, data.form, { name: "广告牌" })
          }

          if (sessionStorage.getItem('roofType') === '斜屋顶') {
            Object.assign(dataTo2d, data.form, { name: "斜屋顶广告牌" })
          }
          if (sessionStorage.getItem('roofType') === '内厦屋顶') {
            Object.assign(dataTo2d, data.form, { name: "内厦屋顶广告牌" })
          }
          if (sessionStorage.getItem('roofType') === '外厦带平台屋顶') {
            Object.assign(dataTo2d, data.form, { name: "外厦带平台屋顶广告牌" })
          }
          // Object.assign(dataTo2d, data.form, { name: "广告牌" })
          emit('popupClose', dataTo2d)
          data.form = {
            EastWestDistance: '',
            SourthNorthDistance: '',
            Height: '',
            XCoordinates: '',
            YCoordinates: '',
            TrueOrFalseRemove: '否',
          }
        }
      },
      cancelEvent() {
        data.form = {
          EastWestDistance: '',
          SourthNorthDistance: '',
          Height: '',
          XCoordinates: '',
          YCoordinates: '',
          TrueOrFalseRemove: '否',
        }
        data.isEdit = true
        emit('popupClose')
      },
      del() {
        const dataTo2d = {}
        // Object.assign(dataTo2d, data.hort, { name: "删除广告牌" })
        if (sessionStorage.getItem('roofType') === '平屋顶') {
          Object.assign(dataTo2d, data.hort, { name: "删除广告牌" })
        }
        if (sessionStorage.getItem('roofType') === '斜屋顶') {
          Object.assign(dataTo2d, data.hort, { name: "删除斜屋顶广告牌" })
        }
        if (sessionStorage.getItem('roofType') === '内厦屋顶') {
          Object.assign(dataTo2d, data.hort, { name: "删除内厦屋顶广告牌" })
        }
        if (sessionStorage.getItem('roofType') === '外厦带平台屋顶') {
          Object.assign(dataTo2d, data.hort, { name: "删除外厦带平台屋顶广告牌" })
        }
        data.form = {
          EastWestDistance: '',
          SourthNorthDistance: '',
          Height: '',
          XCoordinates: '',
          YCoordinates: '',
          TrueOrFalseRemove: '否',
        }
        emit('popupClose', dataTo2d)
        data.isEdit = true
      },
    }

    return {
      ...toRefs(data),
      ...methodsMap
    }
  }
}
</script>
<style scoped lang="scss">
.card {
  margin: 24px 0;
  width: 343px;
  min-height: 360px;
  .title {
    font-size: 20px;
    font-weight: 800;
    color: #1a1a1a;
    text-align: center;
    margin-bottom: 24px;
  }
  .item {
    position: relative;
    span {
      position: absolute;
      right: 17px;
      top: 8px;
      padding: 0 8px;
      font-size: 14px;
      color: #323233;
      font-weight: 700;
      width: 52px;
      height: 27px;
      border: 1px solid #c7c9cc;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 18px;
        height: 18px;
      }
    }
    .options {
      z-index: 1;
      width: 52px;
      height: 68px;
      background: #ffffff;
      border: 1px solid #c7c9cc;
      position: absolute;
      right: 17px;
      top: 35px;
      padding-top: 3px;
      padding-left: 8px;
      font-size: 14px;
      li {
        line-height: 20px;
        font-weight: 700;
        color: #1A1A1A;
      }
      li:active {
        color: #04cc66;
      }
    }
    .twoline {
      height: 48px;
    }
  }
  .btnbox {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    margin-top: 28px;
    .cancel,
    .determine {
      display: inline-block;
      width: 155px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 4px 4px 4px 4px;
      color: #ffffff;
      font-size: 14px;
      font-weight: bold;
    }
    .cancel {
      border: 1px solid #04cc66;
      color: #04cc66;
    }
    .determine {
      background: #04cc66;
    }
  }
}
::v-deep .van-cell-group--inset {
  margin: 0;
}
::v-deep .van-cell:after {
  border: 1px solid rgba(199, 201, 204, 0.6);
}
::v-deep .van-field__label {
  width: 150px;
}
</style>
